/*------------------------ PEOPLE */



a{
    text-decoration: none;
}


.peoplepeople-img-container{
    width: 34%;
    max-width: 400px;
    padding-right: 30px;
    padding-bottom: 15px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.peopleImgInside {
    float: left;
    border-radius: 50%;
    width: 100%;
    aspect-ratio: 1/1;
    border: 5px solid var(--people-color);
    display: block;
    box-sizing: border-box;
}

section.people-sub{
    width:100%;
}

h3.member-link{
    font-family: sans-serif;
}

@media (max-width: 790px) {
    .peoplepeople-img-container{
        width: 100%;
        margin: auto;
        padding-right: unset;
    }

    .people-subpage-title{
        width: 100%;
    }


}
