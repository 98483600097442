.peopleInfo{
    display:block;
}


.peopleCardContainer{
    width: 100%;
    margin:auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.people-filter-wrapper.peoplepage{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    column-gap: 2em;
    width: 100%;
    margin-bottom: var(--mb);
}

.people-height{
    min-height: auto;
}

@-webkit-keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

button.filter-button.people{
    width: 100%;
    border: 3px solid var(--people-color);
    background-color: transparent;
    border-radius: 15px;
    height: 35px;
    font-weight: 500;
    font-family: sans-serif;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: black;
}


button.filter-button.people.active{
    background-color: var(--people-color);
}

@media (max-width:480px) {
    .people-filter-wrapper.peoplepage{
        flex-direction: column;
    }

    button.filter-button.people{
        margin-bottom: 9px;
        border: 3px solid var(--people-color);
    }

    .filter-button{
        max-width: 900px;
        width: 100%;
    }
    
}