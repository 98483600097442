*{
  margin:0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html{
  --main-bg:rgb(255, 255, 255);
  --people-color: rgb(149, 201, 191);
  --people-color-shine: rgba(149, 201, 191, 0.5);
  --projects-color: rgb(118, 166, 190);
  --projects-color-shine: rgba(118, 166, 190,0.5);
  --workarea-color: rgb(221, 191, 209);
  --workarea-color-shine: rgba(221, 191, 209,0.5);

  --mb: 32px;
  --hmb: 18px;

  font-family: sans-serif;
}

.mb{
  margin-bottom:var(--mb);
}

  #workareas {
      width: 100%;
      height: auto;
      margin: auto;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(200deg, var(--workarea-color-shine) 0%, var(--workarea-color) 60%);
      margin-bottom: var(--mb);
      border-radius: 15px;
  }
  section {
      width: 100%;
      height: auto;
      place-items: center;
      overflow: hidden;
      margin-bottom: var(--mb);
      box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.16);
  }
  * {
      margin: 0;
  }
  user agent stylesheet
  section {
      display: block;
  }

  .App {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      font-weight: 400;
      text-align: center;
      position: relative;
      overflow: hidden;
  }

.WYSIWYG * a{
  text-decoration: underline;
  color: inherit;
}

.news-content > .WYSIWYG p:first-child{
  margin-top: 0;
}

.news-content > .WYSIWYG ul{
  margin-top: 1em;
  margin-bottom: 1em;

}

.modal{
  width: 300px;
  position: fixed;
  top:25%;
  left:25%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10%
}

.form-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}

.form>input{
  display: block;
  width:100%
}

/* TYPOGRAPHY */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');


h1{
  font-size:1.8rem;
  font-weight: 500;
  line-height: 120%;
}

h2{
  font-size: 1.65rem;
  font-weight: 500;
}

h3{
  font-size: 1.2rem;
  line-height: 120%;
  font-weight: 500;
}

h4{
  font-size: 0.8rem;
  font-weight: 400;
}

h5{
  font-size: 0.8rem;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6{
  font-family: "Poppins";
  text-rendering: geometricPrecision;
}

p {
  font-family: sans-serif;
  font-size: 17.6px;
  line-height: 120%;
  margin-bottom: 1em;
  font-weight: 400;
  text-size-adjust:none;
  -webkit-text-size-adjust: none;
}

button, button *{
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
}

header, footer *{
  font-family: "Poppins";
  font-weight: 400;
}


/* ELEMENTS */

body {
  width: 100vw;
  min-height: 100vh;
}

section {
  width: 100%;
  height: auto;
  place-items: center;
  overflow: hidden;
  margin-bottom: var(--mb);
  -webkit-box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.16);
  box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.16);
}

.card-item{
  width:calc(100%/4);
  margin: 0% 2% 2% 2%;
  float: left;
  flex-direction: column;
  align-items:center;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  -webkit-animation: fadeinout 0.5s linear forwards;
  animation: fadeinout 0.5s linear forwards;
}

.tooltip-wrapper {
  position: absolute;
  right: 0px;
  bottom: -4px;
  color: black;
}

.img-tooltip-wrapper{
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.wa-tooltip{
  position: relative;
  right: 10px;
  bottom: 10px;
  background-color: white;
  border-radius: 50%;
  width: 130%;
  display: flex;
  align-items: center;
  text-align: center;
}

.home-card-item{
  width:calc(100%/4);
  aspect-ratio: 1/1;
  float: left;
  display: flex;
  flex-direction: column;
  align-items:center;
  text-align: center;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
}



.card-title{
  width: 100%;
  height: auto;
  margin-top: 9px;
  padding: 2%;
  word-wrap:normal;
}

.card-title>h3{
  font-size: 1.1rem;
}

/* BLOBS */
.shape-blob {
	height: 200px;
	width: 200px;
  z-index: -1;
	border-radius: 30% 50% 20% 40%;
  	animation: 
		transform 20s ease-in-out infinite both alternate,
		movement_one 40s ease-in-out infinite both;
	position: absolute;
  opacity: 80%;

}

.shape-blob.one{
  background: var(--people-color);
	height: 200px;
	width: 200px;
	left: -10%;
  top: 500px;
	filter: blur(55px);

  z-index: -1;
	transform: rotate(-180deg);
	animation: transform 30s ease-in-out infinite both alternate, movement_two 60s ease-in-out infinite both;
}

.shape-blob.two{
  background: var(--projects-color);

	height: 250px;
	width: 250px;
	left: 50%;
	top: 500px;
  filter: blur(85px);

  z-index: -1;
	transform: rotate(-180deg);
	animation: transform 30s ease-in-out infinite both alternate, movement_two 60s ease-in-out infinite both;
}

.shape-blob.three{
	background:var(--workarea-color);
  left: 80%;
	top: 800px;
	filter: blur(25px);

  z-index: -1;
	transform: rotate(-180deg);
	animation: transform 30s ease-in-out infinite both alternate, movement_two 60s ease-in-out infinite both;
}

.shape-blob:hover {
  filter: blur(0);
}


@keyframes transform
{
    0%,
  100% { border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%; } 
   20% { border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%; } 
   40% { border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%; } 
   60% { border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%; } 
   80% { border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%; } 
}


@keyframes movement_one
{
    0%,
  100% { 
    transform: none; 
    top: 10px;    
  }
   50% { transform: translate(50%, 20%) rotateY(10deg) scale(1.2);
    top: 100px;    
  }
}

@keyframes movement_two
{
    0%,
  500% { transform: none; }
   50% { transform: translate(50%, 20%) rotate(-200deg) scale(1.2);}
}


/* SECTIONS */

/* PAGES */

.subPageDesc>p{
    margin-top: 0;
}

#workareas {
  width: 100%;
  height: auto;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(200deg, var(--workarea-color-shine) 0%, var(--workarea-color) 60%);
  margin-bottom: var(--mb);
  border-radius: 15px;

}

#people{
  width: 100%;
  height: auto;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(200deg, var(--people-color-shine) 0%, var(--people-color) 60%);
  border-radius: 15px;
  margin-bottom: var(--mb);
}

#publications{
  width: 100%;
  height: auto;
  margin: auto;
  color: white;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(200deg, var(--people-color-shine) 0%, var(--people-color) 60%);
  border-radius: 15px;
  margin-bottom: var(--mb);
}

#projects {
  width: 100%;
  height: auto;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(200deg, var(--projects-color-shine) 0%, var(--projects-color) 60%);
  border-radius: 15px;
  margin-bottom: var(--mb);
}

canvas{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.section-title{
  margin: auto;
  margin-top: var(--hmb);
  margin-bottom: var(--hmb);
  display: block;
  text-align: left;
}
.section-title.sub{
  padding-left: 0;
  display: block;
  text-align: center;
}

.container {
  max-width: 900px;
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: start;
  overflow-wrap: break-word;
}

.subPageDesc{
  text-align: justify;
  margin-bottom: 32px;
}

button{
  cursor: pointer;
  height: 50px;
  border:0;
  }


  .hover-tint{
    display: flex;
    height: fit-content;
    width: fit-content;
  }

  .hover-tint.people{
    border-radius: 50%;
    background-color: var(--people-color);
  }

  .hover-tint.wa{
    background-color: var(--workarea-color);
    border-radius: 15px;
  }

  .project-img.background-tint:hover{
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out;
  }

  .people-img-container:hover{
    opacity: 0.4;
  }

.card-container{
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 5%;
  margin-bottom: 18px;
}

.home-project-img-bg{
  aspect-ratio: 1.2/1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left:-4%;
  background-color: var(--projects-color);
}

.project-img, .home-project-img-bg{
  -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.home-card-container{
  width: 100%;
  min-height: 0%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 5%;
}

.project-home{
  margin-bottom:-3.5%;
}

.publication-year{
  font-size: 13px;
  font-weight: normal;
  float: left;
  text-align: right;
  flex-direction: column;
  position: absolute;
  transform: translate(-125%);
  font-size: 21px;
  font-weight: bold;
}

.info-wrapper{
  width: 100%;
  display: inline-block;
  margin-bottom: var(--mb);
}

.info-wrapper>*>p{
  text-align: justify;
}

p:last-of-type{
  margin-bottom: 0;
}


.info-wrapper>p{
  text-align: justify;
}

.sub-page-detail{
  width: 100%;
  height: auto;
  padding: 1.5em;
  background-color: white;
  color: black;
  border-radius: 15px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.subPageDesc>.WYSIWYG>p{
  margin-top: 0;
  margin-bottom: 0;

}

.publications-wrapper{
  margin: 0em 1.5em 1.5em 1.5em;
}

.subpage-details-wrapper>p:first-child{
  margin-top: 0;
}

.barrier-statement{
  margin-bottom: var(--mb);
  height: auto;
  text-align: justify;
}

.link-span{
  color:green;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  display: inline-block;
  white-space: pre;
}

.homepage-people-item{
  position: absolute;
  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
  border: 5px solid var(--people-color);
}

.homepage-wa-item{
  display: block;

  width: 100%;
  aspect-ratio: 1/1;

  position: absolute;
  top: 0px;
  left: 0px;

  background-size:cover;

  border: 5px solid var(--workarea-color);
  border-radius: 15px;
  box-sizing: border-box;
}

.homepage-projects-item{
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0px;
  left: 0px;
}

.sub>.card-container{
  min-height: 0;
}


@keyframes xfade{
  17% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  92% {
    opacity:0;
  }
}

#projects .card-container{
  min-height: max-content;
  position: relative;
  left:-1%;
}

@media (max-width: 790px) {

  h1{
    font-size: 1.4rem;
  }

  h3{
    font-size: 0.9rem
  }

  .card-title>h3{
    font-size:12px;
  }

  .card-title>h4{
    font-size:11px;
  }

  p{
    font-size: 0.9rem;
    line-height: 1.3em;
    margin-top: 0.5em;
  }

button{
  height: auto;
  
}

  .container{
    width: 87%;
  }

  .home-card-item{
    width: 40%;
  }

  .home-card-item:last-child{
    display: none;
  }

  .card-container {
    row-gap: 1em;
    margin-bottom: 1em;
  }

  .digital-anthropology-lab {
    font-size: 2.8em;
    margin-bottom: 32px;
  }

  section * .card-item{
    width: 44%;
  }

  .sub-page-detail{
    padding: 1em;
  }
  #projects .card-container{
    min-height: max-content;
  }

  .card-title{
    margin-top: 4.5px;
  }
}