.news-feed {
    display: flex;
    flex-direction: column;
  }

  .news-item:nth-child(n) {
    background-color: var(--people-color);
    color: var(--people-color);
  }

  .news-item:nth-child(2n) {
    background-color: var(--workarea-color);
    color: var(--workarea-color);
  }

  .news-item:nth-child(3n) {
    background-color: var(--projects-color);
    color: var(--projects-color);
  }

.news-img{
  width: 100%;
  height: auto;
  margin-bottom: 1em;
}

.date-small{
  margin-top: 0;
  font-size: 0.8rem;
}
  
  .news-item {
    width: 100%;
    float: right;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin-bottom: var(--mb);
    overflow: hidden;
    position: static;
  }

  .news-content{
    width: 100%;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    color:white;
  }
  .news-content>p:first-child{
    margin-top: 0;
  }

  .news-title{
    font-weight: bold;
    float: left;
    text-align: left;
    margin-bottom: 1em;
  }

  .news-date{
    font-size: 13px;
    font-weight: normal;
    float: left;
    text-align: right;
    flex-direction: column;
    position: absolute;
    transform: translate(-125%);
    font-size: 21px;
    font-weight: bold;
  }

  .news-date>p{
    margin-top: 0em;
    margin-bottom: 0em;
    line-height: 120%;
    font-weight: 600;
  }

    .news-content{
    float: left;
    text-align: left;
    color:white;
  }

@media (max-width:480px) {
  .news-item {
    margin-top: 1.2em;
  }
  .news-date{
  display: flex;
  flex-direction: row;
  column-gap: 0.2em;
  transform: translate(0%,-120%);
  }
}