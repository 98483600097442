footer {
  width: 100%;
    color: #00000000;
    padding: 20px 0;
  }
  
  footer nav {
    display: flex;
    justify-content: center;
  }
  
  footer nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  
  footer nav li {
    margin-right: 20px;
  }
  
  footer nav li:last-child {
    margin-right: 0;
  }
  
  footer nav a {
    color: #000000;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;
  }

  footer nav ul li a {
    font-size: 14px;
  }
  
  footer nav a:hover {
    border-bottom: 2px solid black;
  }
  
  @media (max-width: 790px) {
    footer nav li {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    footer nav ul {
      flex-direction: column;
  }
}