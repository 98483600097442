
a {
	color: #000;
	text-decoration: underline;
}

a:visited {
	color: #000;
	text-decoration: underline;
}

a:hover {
	color: #000;
	text-decoration: none;
}


#header {
	max-width: 900px;
	width: 100%;
	float: left;
	overflow: visible;
}

/* @end */

/* -------------    MIHALY RESPONSIVE REDO ------------ */
/* ------MOBILE SIZES */
/* HOMEPAGE / GENERAL */

.header {
	margin: auto;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
  .header ul {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
  	margin-block-start: 0em;
  }
  
  .header li a {
	display: block;
	padding: 15px 15px 15px 1em;
	text-decoration: none;
	font-size: 1.2rem;
	font-weight: 400;
  }
  
  .header li a:hover,
  .header .menu-btn:hover {
    transition: 0.3s;

  }

  .header li {
	float: left;
  }


.header li a:hover {
  color: var(--projects-color);
  }
  
  
  /* menu */
  
  .header .menu {
	clear: both;
  background-color: white;

  }
  
  /* menu icon */
  
  .header .menu-icon {
	cursor: pointer;
	display: inline-block;
    padding: 2.5em 1em;
	position: fixed;
	top:0px;
	right: 0px;
	user-select: none;
	z-index: 1999;
  }
  
  .header .menu-icon .navicon {
	background: #333;
	display: block;
  width: 30px;
	height: 4px;
	position: relative;
	transition: background .2s ease-out;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
	background: #333;
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all .2s ease-out;
	width: 100%;
  }
  
  .header .menu-icon .navicon:before {
	top: 7px;
  }
  
  .header .menu-icon .navicon:after {
	top: -7px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
	display: none;
  }
  

  
  .header .menu-btn:checked ~ .menu-icon .navicon {
	background: transparent;
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
	top: 0;
  }

  .popup-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    backdrop-filter: blur(5px);
    z-index: 999;
  }
  
  .popup-menu.open {
    transform: translateX(0);
  }

  element.style {
}
.header .menu {
    clear: both;
    background-color: white;
}
.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    margin-block-start: 0em;
}

.menu-bar-desktop {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
	position: static;
    justify-content: center;
    background-color: #ffffffeb;
    transform: trasnlateX(0%);
    /* transition: transform 1s ease-in-out; */
    z-index: 99;
}

.menu * h3{
	font-weight: 400;
}

/* desktop size */
@media (max-width: 790px) {

	/* header */


ul.menu{
  margin-block-start: 0em;
}

	.header .menu {
	  clear: none;
	  float: left;
	  max-height: none;
	  height: 105vh;
	background-color: #ffffffeb;
	}

	.header .menu-icon {
	  display: none;
	}

}

.menu-bar-mobile{
	width: 70%;

}

.menu-bar-mobile {
	width: 70%;
	height: 100%;
	position: fixed;
	right: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    overflow: hidden;
    background-color: #ffffffba;
	transform: translateX(100%);
    z-index: 99;
/* 	transition: transform 1s ease-in-out; */
  }
  
  .menu-bar-mobile.active {
	z-index: 260;
    transform: translateX(0%);
    transition: transform 0.7s ease-in-out;
  }
  
  .no-transition {
	transition: none !important;
  }