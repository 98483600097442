  .publication-container h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  

  
.publication-card {
    width: 100%;
    padding-bottom: 1em;
  }

  .publication-card:last-child {
    padding-bottom: 0em;
  }

  .publication-card:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  .publication-wrapper{
    width: 100%;
    float: right;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin-bottom: var(--mb);
    overflow: hidden;
  }

  .publication-wrapper:nth-child(n) {
    background-color: var(--people-color);
    color: var(--people-color);
  }

  .publication-wrapper:nth-child(2n) {
    background-color: var(--workarea-color);
    color: var(--workarea-color);
  }

  .publication-wrapper:nth-child(3n) {
    background-color: var(--projects-color);
    color: var(--projects-color);
  }

  .publication-wrapper * .publication-card *{
    color: white;
  }
  
  .publication-cards{
    padding:1.5em;
    min-width: 0;
  }
  
  .publication-card-content>p {
    margin-top: 0;
  }
  
  .publication-card-content>*>p {
    margin-top: 0;
  }

@media (max-width:480px) {
  .publication-year {
    transform: translate(0,-115%);
    }

  .publication-wrapper{
    margin-top: 1em;
  }
}
