.subpage-title{
    display: flex;
    width: 60%;
}


.wa-sub-img{
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1/1;
    display: flex;
    border-radius:5%;
    box-sizing: border-box;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 5px solid var(--workarea-color);
}

@media (max-width: 790px) {
    .subpage-title{
        width: 100%;
        line-height: 120%;
        margin-bottom: 0.2em;
    }
}