
.info-wrapper{
    width: 100%;
}

.sub-img-wrapper{
    width: 34%;
    max-width: 400px;
    padding-right: 30px;
    padding-bottom: 15px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subpage-description{
    margin-top: 9px;
}

.subpage-description *{
    text-align: justify;
}

.subpage-description * a{
    text-decoration:underline;
}

.project-img{
    width: 93%;
    aspect-ratio: 1.2/1;
    display: flex;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
}

.project-img-bg{
    width: 100%;
    aspect-ratio: 1.2/1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--projects-color);
}

.project-img-bg{
    -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)
}

:-moz-user-disabled
.peoplepeople-img-container{
    width:100%;
  }

@media (max-width: 790px) {
    .sub-img-wrapper{
        width: 100%;
        padding-right: 0;
        padding-bottom: 2em;
    }
}
