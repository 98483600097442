  .moreButton{
    display: flex;
    align-items: center;
    justify-content: center;
  }

.moreButton a button{
  aspect-ratio: 3.5/1;
  margin: auto;
  margin-top:var(--hmb);
  margin-bottom:var(--hmb);
  color: white;
  background-color: transparent;

  border: 2px solid white;
  border-radius: 1em;

  font-size: 1.2rem;
  font-weight: 400;

  cursor: pointer;
}

.moreButton a button:hover{
  background-color: white;
  color:  inherit;

}

@media (max-width:480px) {
  .moreButton a button{
    font-size: 0.8rem;
    width: 130px;
    aspect-ratio: 4/1;
    margin: auto;
    margin-top: var(--hmb);
    margin-bottom: var(--hmb);
    color: white;
    background-color: transparent;
    border: 1.5px solid white;
    border-radius: 15px;
    font-weight: 400;
    cursor: pointer;

  }
}

