

.project-img{
    width: 94%;
    aspect-ratio: 1.2/1;
    display: flex;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
}


.card-item>*>.project-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
}


.card-item>*>.project-img:hover::before {
  background-color: var(--projects-color-shine);
}

.project-img-bg{
    aspect-ratio: 1.2/1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--projects-color);
}

.project-img, .project-img-bg{
  /* -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%) */
}



@-webkit-keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

