.wa-card-title{
    width: 100%;
    height: auto;
    padding: 2%;
    margin-top: 9px;
    word-wrap: normal;
}

.carousel{
  width: 100%;
}

.carousel .wa-card-title{
  height: 3rem;
}

.carousel .card-title{
  height: 4.5rem;
}


.wa-card-img{
  width: 100%;
  aspect-ratio: 1/1;
  background-size:cover;
  display: block;
  position: relative;
  border-radius: 15px;
  top: 0px;
  left: 0px;
  border: 5px solid var(--workarea-color);
  box-sizing: border-box;
}

.wa-card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
}

.wa-card-img:hover{
  background-color: var(--workarea-color-shine);
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;
}

  .background-tint {
    background-color: rgba(0, 0, 0, 0.13);
    background-blend-mode: multiply;
  }

