.filter-wrapper{
        width: 100%;
        margin:auto;
        float: left;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        row-gap: 10px;
        column-gap: 10px;
        margin-bottom: 32px;
      }

    .filter-wrapper.project.mobile{
        margin-bottom: 0px;

    }

      .jsSticky {
        width: 100%;
        height: 60px;
        background: firebrick;
        position: relative;
     }
     
     .sticky {
        position: fixed;
        top: 0;
        width: 100%;
     }

      .filter-category{
        width: 100%;
        display: flex;
        float: left;
        row-gap: 16px;
        column-gap: 10px;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 1em;
      }

.collection{
    height: 30px;;
    color: white;
    font-weight: bolder;
    width: 150px;
    background-color: rgb(167, 238, 246);
    float: right;
    vertical-align: middle;
    line-height: 30px;
    border-radius: 15px;

}


.filter-button-projects{
    width: calc(100%/4.2);
    aspect-ratio: 3/1;
    border-radius: 15px;
    height: 35px;
    font-weight: 500;
    font-family: sans-serif;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: black;
}

.project-list-container{
    min-height: 100vh;
}

.filter-wrapper .active{
    background-color: var(--workarea-color);
    border: 3px solid var(--workarea-color);
}

.topic{
    border: 3px solid var(--workarea-color);
}

.date-filter{
    border: 3px solid var(--projects-color);
    font-weight: 500;
}
.activeDate{
    background-color: var(--projects-color);
    border: 3px solid var(--projects-color);
}

.type{
    border: 3px solid var(--projects-color);
    font-weight: 500;
}
.activeType{
    background-color: var(--projects-color);
    border: 3px solid var(--projects-color);
}

.whiteBg{
    background-color: white;
}

.mobile-filter-container{
    display: none;
}

.filter-accordion{   
    display: flex;
    align-items: center;
    justify-content:center;
}



.side-filter{
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 1em 1em 1em 1em;
    padding:32px 0em 32px 0em;
}

.filter-arrow{
    position: absolute;
    right: 1em;
    transform: translate(0, -50%);
    transform: rotateZ(180deg);
    font-size: 1.5rem;
    transition: transform 1s ease-in-out;
}

.filter-arrow.active{
    transform: rotateZ(0deg);
    transition: transform 1s ease-in-out;
}

.accordion {
    color: var(--projects-color);
    cursor: pointer;
    margin-bottom: 2em;
    width: 100%;
    text-align: left;
    outline: none;
  }
  
  .panel {
    position: absolute;
    right: 0;
    top:420px;
    padding: 1em 0em 1em 0em;
    border: none;
    color: #444;
    width: 100vw;
    overflow: hidden;
    background-color: #ffffffba;
    transform: translateX(100%);
    transition: transform 0.7s ease-in-out;
    z-index: 99;
  }
  
  .panel.active {
    transform: translateX(0%);
    transition: transform 0.7s ease-in-out;
  }
  

@media (max-width:480px) {
    .filter-button-projects{

        width: 70%;
        max-width:900px;
    }
    
    .project-list-container{
        min-height: 100vh;
    }
}