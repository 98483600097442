.digital-anthropology-lab {
  height: auto;
  margin:auto;
  padding:5%;
  margin:0% 0% 32px 0%;

  font-family: 'Roboto Mono', monospace;
  font-size: 9vw;
  font-weight: bold;
  text-align: center;

  background: linear-gradient(-45deg, var(--people-color-shine), #ffff, var(--projects-color-shine));
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;


}

.digital-anthropology-lab>h1{
	font-family: 'Roboto Mono', monospace;
  	font-size: 3.8rem;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media (max-width:480px) {
	.digital-anthropology-lab>h1{
		font-size: 0.8em;
	  }
	
}