.workAreaContainer{
    margin:auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

@media (max-width: 790px) {
    .card-item{
        width: 44%;
        box-sizing: border-box;
    }
}